<template>
    <a-modal
            :title="title"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @cancel="$emit('close')"
            @ok="handleSubmit"
            destroyOnClose
    >
        <a-form
                :form="form"
                v-bind="formItemLayout"
                v-if="visible"
        >
            <!--<a-form-item label="上传图片">
                <a-upload
                        name="avatar"
                        list-type="picture-card"
                        :show-upload-list="false"
                        action=""
                        :before-upload="beforeUpload"
                        @change="handleChange"
                        :customRequest="customRequest"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                    </div>
                </a-upload>
                <span>大小不超过10M,格式为jpg</span>
            </a-form-item>-->
            <a-form-item label="课题名称">
                <a-input v-model="name" placeholder="请输入课题名称" />
            </a-form-item>
            <a-form-item label="首页展示">
                <a-switch :defaultChecked="show" @change="setShow()"/>
            </a-form-item>
            <a-form-item label="排序序号">
                <a-input v-model="sortby" placeholder="从小到大 优先排序" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/analysis'
    export default {
        name: 'addSubject',
        props: [ 'visible', 'data' ],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        watch:{
            visible(val){
                if(val){

                    if(this.data.length == 0){
                        this.title = "新增学科"
                        this.name = ''
                        this.show = false
                        this.sortby = 0
                    }else{
                        this.title = "编辑学科"
                        this.name = this.data.name
                        this.show = this.data.show
                        this.sortby = this.data.sortby
                    }
                }
            }
        },
        data(){
            return{
                confirmLoading: false,
                loading: false,
                title: '',
                name: '',
                show:false,
                sortby:'',
                formItemLayout: {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18 },
                },
                url:''
            }
        },
        methods:{
            setShow(e){
                this.show = !this.show;
            },
            handleSubmit(){
                this.confirmLoading = true
                if(this.data.length == 0){
                    http.add_subject({name: this.name, show: this.show, sortby: this.sortby}).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                        this.confirmLoading = false
                    }).catch((error)=>{
                        console.log(error)
                        this.confirmLoading = false
                    })
                }else{
                    http.edit_subject({name: this.name, show: this.show, sortby: this.sortby, id:this.data.id}).then(res=>{
                        this.$emit('close')
                        this.$emit('update')
                        this.confirmLoading = false
                    }).catch((error)=>{
                        console.log(error)
                        this.confirmLoading = false
                    })
                }
            },
            beforeUpload(){

            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.imageUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            handleChange(){

            }
        }
    }
</script>
