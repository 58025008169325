<template>
    <div class="pd20x flex1">
        <Loading v-if="loading"></Loading>
        <addSubject :visible="visible" @close="visible = false" :data="currentItem" @update="getData"></addSubject>
        <div class="h100pct ofA">
            <div class="filter pd20x mb10x">
                <div>学科数目 : {{total}}</div>
                <div>
                    <a-button type="primary" @click="addItem">添加</a-button>
                </div>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="result"
                    :pagination="pagination"
                    :rowKey="'id'"
                    @change="changePage"
            >
                <template slot="name" slot-scope="text">
                    {{text}}
                </template>
                <template slot="showList" slot-scope="text, record,index">
                    <a-switch :defaultChecked="record.show" @change="setShow(index,record.id)"/>
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a-space>
                        <a-button type="primary" style="margin-right:10px" @click="()=>{currentItem = record ; visible = true}">修改学科</a-button>
                        <a-button type="primary" ghost style="margin-right:10px" @click="$router.push({path:'/analysis/unitList', query: {name:record.name,class: record.id}})">解析管理</a-button>
                    </a-space>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
    const columns = [
        {
            title: '学科名称',
            dataIndex: 'name',
            scopedSlots: { customRender: 'className' },
            align:'center'
        }, {
            title: '首页展示',
            dataIndex: 'show',
            align:'center',
            scopedSlots: { customRender: 'showList' },
        }, {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];
    import * as http from '@/libs/analysis'
    import addSubject from '@/components/analysis/addSubject'
    import Loading from '@/components/loading'
    export default {
        name: 'classList',
        components: { Loading,addSubject },
        data(){
            return{
                result: [],
                columns,
                visible: false,
                loading: true,
                currentItem: [],
                total: 0,
                filter:{
                    cate_id: 0,
                    keywords:''
                },
                pagination:{
                    page: 1,
                    size: '15',
                    pageSize: 15
                },
            }
        },
        mounted(){
            this.$nextTick(()=>{
                this.getData()
            })
        },
        methods:{
            changePage({current}){
                this.pagination.page = current
                this.getData()
            },
            setShow(index , id){
                this.loading = true
                http.show_subject({id:id}).then(res=>{
                    this.loading = false;
                    this.result[index]['show'] = !this.result[index]['show'];
                }).catch(error=>{
                    this.loading = false
                    console.log(error)
                })
            },
            getData(){
                this.result = [];
                this.loading = true;
                let request = {...this.filter,...this.pagination}
                if(this.filter.cate_id == 0){
                    request.cate_id = null
                }
                http.get_subject(request).then(res=>{
                    this.loading = false
                    this.result = res.data
                    this.total = res.total
                }).catch(error=>{
                    this.loading = false
                    console.log(error)
                })
            },
            handleChange(){

            },
            onSelectChange(){

            },
            addItem(){
                this.currentItem = [];
                console.log(this.currentItem)
                this.visible = true;
            },
            deleteItem(id){
                let _this = this
                this.$confirm({
                    title: '是否确定删除该学科?',
                    centered: true,
                    onOk() {
                        http.del_subject({id}).then(res=>{
                            _this.getData()
                        }).catch(error=>{
                            console.log(error)
                        })
                    },
                });
            },
            hideModal(){

            }
        }
    }
</script>
<style lang="less" scoped>

</style>
