import { render, staticRenderFns } from "./classList.vue?vue&type=template&id=432c5db5&scoped=true&"
import script from "./classList.vue?vue&type=script&lang=js&"
export * from "./classList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432c5db5",
  null
  
)

export default component.exports